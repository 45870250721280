.leaflet-container.marker-cursor-enabled {
  cursor: url("../../markerIcon.png") 16 32, auto;
}

.marker-cursor-enabled .leaflet-interactive {
  cursor: url("../../markerIcon.png") 16 32, auto;
}

.center {
  text-align: center;
}

.leaflet-popup-content-wrapper {
  padding: 10px;
  height: auto;
  max-width: 200px;
}

.buttonDownloadKml {
  font-weight: 500;
  background: none;
  margin-top: 13px;
  display: inline;
  width: auto;
  height: auto;
  padding-left: 20px;
  margin-right: 4px;
  line-height: 1em;
  border: 0;
  text-align: left;
  background-image: url(../img/download-icon.png);
  font-family: sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 12px 12px;
  color: #5e66cc;
  text-decoration: none;
}

.buttonDownloadKml:hover {
  color: #9ca0db;
}
.rasterDeleteMsg {
  margin: 0px 16px 0px 16px !important;
}