.closebtn {
    padding-left: 10px;
    color: #FFFFFF;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
  }
  
  .chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #44c47d;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }

.inputLabel{
    color: #FFFFFF; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;    
    padding-left: 4px;    
    margin-bottom: unset;
    margin-top: unset;         
}

.input {
    border-width:0px;
    border: none;
    width: 3rem;
    height: 50%;
}