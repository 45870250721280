/* HOME */

.farmerSections{
  /* position: absolute; */
  /* top: 20px; */
  width: 100%;
  margin-top: "20px" !important
}

.farmerSections .rowSectionsFarmer{
  display: flex;
  flex-wrap: wrap;
  place-content: center space-evenly;
  align-items: center;
  width: 100%;
  margin: 20px 0px 0px 0px;
}

/* CREAR FARMER */
.createFarmerColumnDragLeft {
  height: 41vh;
  overflow: auto;
  float: left;
  width: 49%;
  list-style: none;
  min-height: 40vh;
  border-radius: 12px;
  margin-right: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.createFarmerColumnDragRight {
  height: 41vh;
  overflow: auto;
  float: left;
  width: 49%;
  list-style: none;
  background: #ddd;
  min-height: 40vh;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.rowDragCreateFarmer {
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.titleCreateFarmer {
  font-size: 15px !important;
  font-weight: bold;
  margin-bottom: 0;
  cursor: pointer;
  transition: 0.2s;
}

.listRight {
  padding:10px
}

.listLeft {
  padding:10px
}

.inputCreateFarmer {
  height: 35px;
  min-width: 300px;
  border: none;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  text-align: center;
  margin-left: 50%;
  border-radius: 10px 10px 0px 0px;
  outline: none;
}
/* EDITAR FARMER */
.hoverFocusElement{
  background-color: #ddf8e8 !important;
}
.layout__wrapper {
  background-image: linear-gradient(
    0deg,
    hsl(215, 14%, 16%) 0%,
    hsl(215, 19%, 29%) 100%
  );
  border-radius: 3px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25);
  margin: 3rem auto;
  max-width: 370px;
}

.cardFarmers {
  padding: 8px !important;
  border-radius: 5px;
}

.editFarm-store-container {
  height: 40px;
  text-align: center;
  padding: 0px;
  position: relative;
  border-radius: 10px 10px 0px 0px;
}

.items-container {
  padding: 10px;
}
.scrollContainer{
  height: 41vh;

  overflow: auto;
}
.editFarm-item-container {
  background-color: white;
}

.rowDragEditFarm {
  height: 41vh;
  margin-top: 2vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.titleEditFarmer {
  font-size: 15px !important;
  font-weight: bold;
  margin-bottom: 0;
  cursor: pointer;
  color: #51cbce !important;
  transition: 0.2s;
}

.editFarm-titleFarmsList {
  outline: none;
  text-align: center;
  border: none;
  font-size: 20px;
  height: 100%;
  color: #fff;
  font-weight: 700;
  margin: 0;
  padding: 5px;
  background: #5db283;
}
.editFarm-titleFarmsList :focus{
  outline:none;
} 
.au{
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}



/* TOAST */

.toast-position {
  bottom: 8rem !important;
}

  .columnFarmDragRight{
    height: 41vh;
    overflow: auto;
    float: left;
    width: 49%;
    list-style: none;
    background: #ddd;
    /* min-height: 500px; */
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }
  .columnFarmDragLeft{
    height: 41vh;
    float: left;
    width: 49%;
    list-style: none;
    /* min-height: 500px; */
    border-radius: 12px;
    margin-right: 10px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }


@media screen and (max-width: 585px) {
  .scrollContainer{
    overflow: hidden;
  }
  .columnFarmDragLeft {
  overflow: hidden;
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
}
.columnFarmDragRight  {
  width: 100%;
  overflow: hidden;

}
.rowDragEditFarm{
  overflow: auto;
  height: 41vh;
}
  .createFarmerColumnDragLeft {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
  }
  .createFarmerColumnDragRight  {
    width: 100%;

  }
  .inputCreateFarmer{
      margin-left: 0;
  }
  .cardFarmers{
      width: auto;
      min-width: 0;
  }
}

