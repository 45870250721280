.content-form-sowing {
  display: flex;
  min-width: max-content;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.content-form-sowing p {
  color: red;
  margin: 0;
}
.content-form-sowing {
  margin-left: 2px;
}

 p{
  margin: 0 !important;
}

.caretDashboard{
  font-size: large;
}