.main-panel {
    height: 100%;
}

.main-panel.table-responsive{
    position: relative;
    background: lightgray !important;
}

button > img,
button > span {
  vertical-align: middle;
}