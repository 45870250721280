    /*
    Flaticon icon font: Flaticon
    Creation date: 18/09/2020 15:24
    */

    @font-face {
  font-family: "Flaticon";
  src: url("~assets/fonts/Flaticon.eot");
  src: url("~assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("~assets/fonts/Flaticon.woff2") format("woff2"),
       url("~assets/fonts/Flaticon.woff") format("woff"),
       url("~assets/fonts/Flaticon.ttf") format("truetype"),
       url("~assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("~assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-field:before { content: "\f100"; }
.flaticon-location:before { content: "\f101"; }
.flaticon-field-1:before { content: "\f102"; }
.flaticon-land:before { content: "\f103"; }
.flaticon-farming:before { content: "\f104"; }
.flaticon-mechanical-arm:before { content: "\f105"; }
.flaticon-leaf:before { content: "\f106"; }
.flaticon-sprinkler:before { content: "\f107"; }
.flaticon-solar-panel:before { content: "\f108"; }
.flaticon-plant:before { content: "\f109"; }
.flaticon-greenhouse:before { content: "\f10a"; }
.flaticon-farm:before { content: "\f10b"; }
.flaticon-tree:before { content: "\f10c"; }
.flaticon-light-aircraft:before { content: "\f10d"; }
.flaticon-dna:before { content: "\f10e"; }
.flaticon-leaf-1:before { content: "\f10f"; }
.flaticon-drone:before { content: "\f110"; }
.flaticon-farm-1:before { content: "\f111"; }
.flaticon-tractor:before { content: "\f112"; }
.flaticon-wheat:before { content: "\f113"; }
.flaticon-bulb:before { content: "\f114"; }
.flaticon-light:before { content: "\f115"; }
.flaticon-screen:before { content: "\f116"; }
.flaticon-smartphone:before { content: "\f117"; }
.flaticon-laptop:before { content: "\f118"; }
.flaticon-placeholder:before { content: "\f119"; }
.flaticon-land-1:before { content: "\f11a"; }
.flaticon-sprinkler-1:before { content: "\f11b"; }
.flaticon-power:before { content: "\f11c"; }
.flaticon-soil:before { content: "\f11d"; }
.flaticon-leaf-2:before { content: "\f11e"; }
.flaticon-plant-1:before { content: "\f11f"; }
.flaticon-growth:before { content: "\f120"; }
.flaticon-farming-and-gardening:before { content: "\f121"; }
.flaticon-plane:before { content: "\f122"; }
.flaticon-dna-1:before { content: "\f123"; }
.flaticon-leaf-3:before { content: "\f124"; }
.flaticon-drone-1:before { content: "\f125"; }
.flaticon-wheat-1:before { content: "\f126"; }
.flaticon-tractor-1:before { content: "\f127"; }
.flaticon-sprout:before { content: "\f128"; }
.flaticon-farm-2:before { content: "\f129"; }
.flaticon-app:before { content: "\f12a"; }
.flaticon-leaf-4:before { content: "\f12b"; }
.flaticon-silo:before { content: "\f12c"; }
.flaticon-satellite-station:before { content: "\f12d"; }
.flaticon-ecology:before { content: "\f12e"; }
.flaticon-leaf-5:before { content: "\f12f"; }
.flaticon-drone-2:before { content: "\f130"; }
.flaticon-cow:before { content: "\f131"; }
.flaticon-cow-1:before { content: "\f132"; }
.flaticon-seed:before { content: "\f133"; }
.flaticon-flask:before { content: "\f134"; }
.flaticon-physics:before { content: "\f135"; }
.flaticon-agronomy:before { content: "\f136"; }
.flaticon-test:before { content: "\f137"; }
.flaticon-farm-3:before { content: "\f138"; }
.flaticon-farm-4:before { content: "\f139"; }
.flaticon-work:before { content: "\f13a"; }
.flaticon-wheat-2:before { content: "\f13b"; }
.flaticon-weather:before { content: "\f13c"; }
.flaticon-pesticide:before { content: "\f13d"; }
.flaticon-drop:before { content: "\f13e"; }
.flaticon-night:before { content: "\f13f"; }
.flaticon-flash:before { content: "\f140"; }
.flaticon-wheat-3:before { content: "\f141"; }
.flaticon-rice:before { content: "\f142"; }
.flaticon-fence:before { content: "\f143"; }
.flaticon-yard:before { content: "\f144"; }
.flaticon-sprout-1:before { content: "\f145"; }
.flaticon-sprout-2:before { content: "\f146"; }
.flaticon-honey:before { content: "\f147"; }
.flaticon-profits:before { content: "\f148"; }
.flaticon-bar-chart:before { content: "\f149"; }
.flaticon-graph:before { content: "\f14a"; }
.flaticon-graph-1:before { content: "\f14b"; }
.flaticon-analysis:before { content: "\f14c"; }
.flaticon-bar-chart-1:before { content: "\f14d"; }
.flaticon-bar-chart-2:before { content: "\f14e"; }
.flaticon-cuadrado:before { content: "\f14f"; }
.flaticon-arroz:before { content: "\f150"; }
.flaticon-intercambio:before { content: "\f151"; }
.flaticon-satelite:before { content: "\f152"; }
    
    $font-Flaticon-field: "\f100";
    $font-Flaticon-location: "\f101";
    $font-Flaticon-field-1: "\f102";
    $font-Flaticon-land: "\f103";
    $font-Flaticon-farming: "\f104";
    $font-Flaticon-mechanical-arm: "\f105";
    $font-Flaticon-leaf: "\f106";
    $font-Flaticon-sprinkler: "\f107";
    $font-Flaticon-solar-panel: "\f108";
    $font-Flaticon-plant: "\f109";
    $font-Flaticon-greenhouse: "\f10a";
    $font-Flaticon-farm: "\f10b";
    $font-Flaticon-tree: "\f10c";
    $font-Flaticon-light-aircraft: "\f10d";
    $font-Flaticon-dna: "\f10e";
    $font-Flaticon-leaf-1: "\f10f";
    $font-Flaticon-drone: "\f110";
    $font-Flaticon-farm-1: "\f111";
    $font-Flaticon-tractor: "\f112";
    $font-Flaticon-wheat: "\f113";
    $font-Flaticon-bulb: "\f114";
    $font-Flaticon-light: "\f115";
    $font-Flaticon-screen: "\f116";
    $font-Flaticon-smartphone: "\f117";
    $font-Flaticon-laptop: "\f118";
    $font-Flaticon-placeholder: "\f119";
    $font-Flaticon-land-1: "\f11a";
    $font-Flaticon-sprinkler-1: "\f11b";
    $font-Flaticon-power: "\f11c";
    $font-Flaticon-soil: "\f11d";
    $font-Flaticon-leaf-2: "\f11e";
    $font-Flaticon-plant-1: "\f11f";
    $font-Flaticon-growth: "\f120";
    $font-Flaticon-farming-and-gardening: "\f121";
    $font-Flaticon-plane: "\f122";
    $font-Flaticon-dna-1: "\f123";
    $font-Flaticon-leaf-3: "\f124";
    $font-Flaticon-drone-1: "\f125";
    $font-Flaticon-wheat-1: "\f126";
    $font-Flaticon-tractor-1: "\f127";
    $font-Flaticon-sprout: "\f128";
    $font-Flaticon-farm-2: "\f129";
    $font-Flaticon-app: "\f12a";
    $font-Flaticon-leaf-4: "\f12b";
    $font-Flaticon-silo: "\f12c";
    $font-Flaticon-satellite-station: "\f12d";
    $font-Flaticon-ecology: "\f12e";
    $font-Flaticon-leaf-5: "\f12f";
    $font-Flaticon-drone-2: "\f130";
    $font-Flaticon-cow: "\f131";
    $font-Flaticon-cow-1: "\f132";
    $font-Flaticon-seed: "\f133";
    $font-Flaticon-flask: "\f134";
    $font-Flaticon-physics: "\f135";
    $font-Flaticon-agronomy: "\f136";
    $font-Flaticon-test: "\f137";
    $font-Flaticon-farm-3: "\f138";
    $font-Flaticon-farm-4: "\f139";
    $font-Flaticon-work: "\f13a";
    $font-Flaticon-wheat-2: "\f13b";
    $font-Flaticon-weather: "\f13c";
    $font-Flaticon-pesticide: "\f13d";
    $font-Flaticon-drop: "\f13e";
    $font-Flaticon-night: "\f13f";
    $font-Flaticon-flash: "\f140";
    $font-Flaticon-wheat-3: "\f141";
    $font-Flaticon-rice: "\f142";
    $font-Flaticon-fence: "\f143";
    $font-Flaticon-yard: "\f144";
    $font-Flaticon-sprout-1: "\f145";
    $font-Flaticon-sprout-2: "\f146";
    $font-Flaticon-honey: "\f147";
    $font-Flaticon-profits: "\f148";
    $font-Flaticon-bar-chart: "\f149";
    $font-Flaticon-graph: "\f14a";
    $font-Flaticon-graph-1: "\f14b";
    $font-Flaticon-analysis: "\f14c";
    $font-Flaticon-bar-chart-1: "\f14d";
    $font-Flaticon-bar-chart-2: "\f14e";
    $font-Flaticon-cuadrado: "\f14f";
    $font-Flaticon-arroz: "\f150";
    $font-Flaticon-intercambio: "\f151";
    $font-Flaticon-satelite: "\f152";