.suggestions {
  padding: 5px;
  background: white;
  position: absolute;
  z-index: 2;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  top: 37px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.suggestionSelect {
  cursor: pointer;
}

.suggestionSelect:hover {
  background-color: #dee2e6;
}
