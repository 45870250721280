.b-padding30 {
    padding: 0 30px!important;
    padding-bottom: 30px!important;
}
.p-padding40 {
    padding-left: 40px !important;
}
.m-minheight {
    min-height: 332px !important;
}
.p-paddingR40 {
    padding-right: 40px !important;
}
.p-paddingT {
    margin-top: 12px;
}
.p-margin {
    margin-left: 12px !important;
}
.p-paddingTt {
    margin-top: 40px !important;
}
.p-paddingB28 {
    padding-bottom: 28px !important;
}
.p-paddingB12 {
    padding-bottom: 12px !important;
}
.f-font {
    font-size: 1rem;
}
.c-color {
    font-weight: bold;
    color: #539e66;
}
.d-flex{
    display: flex;
    align-items: center !important;
}
.d-space{
    justify-content: space-between;
}
.c-title{
    padding-left: 10px;
    border-left: 5px solid #539e66;
    border-radius: 4px;
}
.m-marginR65 {
    margin-right: 65px;
}
.p-nopadding {
    padding: 0 !important;
}
.p-position {
    top: 50%;
    transform: translateY(49%);
}
.h-min {
    min-height: 448px!important;
}
.accordion__button{
    outline: none;
}
.height100{
    height: 100%;
}
.height-auto{
    height: auto;
}
.p-transform {
    bottom: 50%;
    transform: translateY(24%);
}
.m-bottom {
    margin-bottom: 18px
}
.icon-style {
    width: 23px;
    height: 23px;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #ffffff !important;
    color: #66615B;
    cursor: not-allowed;
}
.p-title{
    padding-left: 15px;
    font-size: 16px;
    color: #838383;
}
.p-paddingL22{
    margin-left: 22px;
}

.table th, .table td {
    border-top: none !important;
    border-bottom: 1px solid #dee2e6;
}

.sidebar-wrapper>ul.nav>li>div {
    margin-left: 10px;
}

.sidebar-wrapper>ul>li ul>li>a {
    font-size: 14px !important;
}

.sidebar-wrapper>ul>li ul>li ul>li>a{
    font-size: 13px !important;
}

.sidebar-wrapper>ul>li ul>li .caret{
    top : 10px !important;
}

.sidebar-wrapper>ul>li>div li>div {
    margin-left:10px;
}

.sidebar[data-active-color="success"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active>a .sidebar-mini-icon {
    color: #6bd098 !important;
}
.sidebar[data-active-color="success"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li>a .sidebar-mini-icon {
    color: #fff !important;
}
