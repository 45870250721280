.cardTableLicences {
    margin-left: 10px;
    margin-right: 10px; 
    margin-top: 50px;
}

.titleCreateLicenses {
    font-size: 15px !important;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;
    transition: 0.2s;
  }

.createLicense-store-container {
  background-color: #66d0be;
  border-radius: 10px 10px 0 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.createLicense-farmerContainer{
  background-color: #5db283;
  border-radius: 10px 10px 0 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.createLicense-titleFarmsList {
  margin: 0;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
}
.createLicense-item-container {
  padding: 10px;
}

.createLicense-item-container {
  background-color: white;
}

.licScreen .table-responsive{
    max-height: 550px !important;
    margin-bottom: 10px;
    overflow: auto !important;
}

.tableNotScroll .table-responsive{
    overflow: hidden;
    height: 94vh;
}


.cardLicences{
    padding: 8px !important;
    border-radius: 5px;
}

.cardLicences label{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    cursor: auto;
    transition: 0.2s;
}



/* CREATE LICENCES */

.arrowLeft {
  border: solid #5db283;
  border-width: 0 3px 3px 0;
  margin: 5px;
  margin-right:8px;
  padding: 6px;
  margin-left: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrowRight {
  border: solid #e82d2d;
  border-width: 0 3px 3px 0;
  margin: 5px;
  margin-right: 2px;
  padding: 6px;
  margin-left: 8px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.stickyPosition{
  outline: none;
  position: absolute;
  bottom: 50%;
  height: 0px;
  /* margin-right: -15px; */
  left: 48.4%;
}
.containerButton{
  position: absolute;
  left: 103%;
}
.containerButton2{
  position: absolute;
  left: 103%;
}

.containerButton button{
  border-radius: 100%;
  border: 3px solid #5db283;
  padding: 0px;
}
.containerButton2 button{
  border-radius: 100%;
  border: 3px solid #e82d2d;
  padding: 0px;
}


.inputCreateLicense {
    height: 35px;
    min-width: 300px;
    border: none;
    box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    text-align: center;
    margin-left: 50%;
    border-radius: 10px 10px 0px 0px;
    outline: none;
  }


  .listLeft {
    padding:10px
  }


  .createLicenseColumnDragLeft {
    height: 47vh;
    overflow: auto;
    float: left;
    width: 47%;
    list-style: none;
    min-height: 40vh;
    border-radius: 12px;
    margin-right: 40px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }

    .createLicenseColumnDragRight{
      height: 47vh;
      overflow: auto;
      float: left;
      width: 47%;
      list-style: none;
      background: #ddd;
      min-height: 40vh;
      border-radius: 12px;
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      background: #ffffff;
    }

  .listRight {
    padding:10px
  }

  .rowDragCreateLicense {
    position: relative;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


  .areaTotal{    
    width: 100%;
    position: relative;
  }

  .areaTotal span{
    position: absolute;
    border-radius: 0px 0px 10px 10px;
    background: white;
    min-width: 70px;
    left: 72%;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    padding: 5px;
  }
  @media screen and (max-width: 735px) {
    .createLicenseColumnDragLeft {
        width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
    }
    .createLicenseColumnDragRight  {
      width: 100%;

    }
    .inputCreateLicense{
        margin-left: 0;
    }
    .cardLicences{
        width: auto;
        min-width: 0;
    }

    .containerButton{
      right: 89%;
      top: 58vh;
      position: absolute;
      height: 20px;
      -webkit-transform: rotate(90deg);
    }
    .containerButton2{
      right: 89%;
      top: 58vh;
      position: absolute;
      height: 20px;
      -webkit-transform: rotate(90deg);
    }
  }

  @media screen and (min-width: 1350px) {
    .containerButton{
      left: 101.6%;
    }
    .containerButton2{
      left: 101.6%;
    }
    .stickyPosition{
      left: 48.9%;
    }
  }

