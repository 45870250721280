.img_css {
    max-width: 100%;
    border-radius: $border-radius-small;
}
img{
    max-width: 100%;

}
.img-raised{
    box-shadow: $box-shadow-raised;
}
