  .hoverFocusElement{
    background-color: #ddf8e8 !important;
  }
  .header {
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  
  .reorgField-store-container {
    text-align: center;
    padding: 0px;
    background-color: #66d0be;
    position: relative;
    border-radius: 10px 10px 0px 0px;
  }
  .reorgField-store-container :focus{
    outline:none;
  } 
  .reorgField-items-container {
    text-align: center;
    padding:10px;
  }
  
  .reorgField-item-container {
    padding: 10px;
    background-color: white;
  }
  .editPolygonIcon{
    display: flex;
    justify-content: center;
    position: relative;
  }
  .editPolygonIcon button{
    position: absolute;
    right: 25px
  }
  .editPolygonIcon input{
    background: none;
    border: none;
    text-align: center;
    outline:none;
  }

  .titleReorgFields {
    font-size: 15px !important;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;
    color: #51cbce !important;
    transition: 0.2s;
  }


  .rowDragReorgFields {
      height: 41vh;
  /* overflow: auto; */
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
  }
 
.columnLeftContainer {
  overflow:auto;
  height: 100%;
  float: left;
  width: 49%;
  list-style: none;
  border-radius: 12px;
  margin-right: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.columnRightContainer{
    width: 49%;
    overflow:auto;
    height: 100%;
    float: left;
    list-style: none;
    background: #ddd;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
}

  .reorgField-titleFields {
    display: flex;
    flex-direction: column;
    border: none;
    font-size: 20px;
    margin: 0;
    padding: 5px;
    background: #66d0be;
    border-radius: 10px 10px 0px 0px;
    height: 100%;
  }
  .reorgField-titleFields span {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }

  .reorgField-titleFields input {
    text-align: center;
    border: none;
    font-size: 20px;
    height: 100%;
    color: #fff;
    font-weight: 700;
    background: #66d0be;
  }
  .reorgField-titleFields input :focus{
    outline:none;
  } 
.cardReorgFields {
  padding: 15px;
  border-radius: 5px;
}

.mapDetails{
  padding: 8px;
  height: 30.6vh;
  margin: 3px 20px 0px;
}
@media screen and (max-width: 585px) {
  .rowDragReorgFields {
    overflow: auto;
  }
  .columnLeftContainer  {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
  }
  .columnRightContainer  {
    overflow: hidden;
    width: 100%;

  }
.cardReorgFields{
  padding: 5px;
}

.titleReorgFields{
  font-size: 10px;
}
}

@media screen and (max-height: 750px) {
  .rowDragReorgFields {
    height: 33vh;
  }
  .mapDetails{
    height: 25.6vh;
  }
}

@media screen and (max-height: 550px) {
  .rowDragReorgFields {
    height: 28vh;
  }
  .mapDetails{
    height: 23.6vh;
  }
}