.empty-view-footer {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    column-gap: 20px;
}

.empty-view-modal-footer {
    display: flex;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    border-top: 1px solid #DDDDDD;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(221, 221, 221);
}

.filterEnabled {
    background-color: #44c47d !important;
    color: #FFFFFF !important;
    box-shadow: none !important;
    border: 2px solid #6bd098 !important;
    border-width: 2px; 
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border-color: #44c47d !important;
    border: none;
    margin: 10px 1px;
    border-radius: 8px;
    padding: 11px 22px;
    outline: none !important;
}

.filterDisabled {
    background: transparent !important;
    border: 2px solid #6bd098 !important;
    color: #44c47d !important;
    border-width: 2px;
    border-color: #44c47d !important;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 10px 1px;
    border-radius: 8px;
    padding: 11px 22px;
    outline: none !important;
}

.buttonSections{
 background: transparent;
 border: none;
 color: #FFFFFF;
 text-transform: uppercase;
}

.buttonSections:hover{
    color: #44c47d;
}

.caret-rotation{
    transform: rotate(180deg);
}