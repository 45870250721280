.iconNumberNotify {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 8px;
  margin-left: 10px;
  margin-top: -5px;
}

.iconNumberNotify p {
  background: red;
  border-radius: 50%;
  color: white;
  margin: 0 auto;
  padding: 5px;
  height: 15px;
  padding: 2px;
}
.edit-dropdown .dropdown-menu .dropdown-item {
  padding: 5px !important;
  color: #000;
}

.itemDropdown {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 5px;
  width: 300px;
  box-sizing: border-box;
}

.itemDropdown:hover {
  color: white;
  background-color: #66615b;
  border-radius: 12px;
}

.edit-dropdown .parrafoItemDropdown {
  font-size: 13px;
}

.closeDropdownItem {
  font-size: x-large;
  display: block;
  border: none;
  background: none;
  float: right;
  padding: 0;
  color: red;
}
